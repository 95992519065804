import ManagerTableModel from '@/common/models/ManagerTableModel';
import * as XLSX from 'xlsx';
import { HEADERS } from '@/common/constants/managerTableHeaders';

const NOT_DATA_TEXT = '-';

export default class ManagersToTable {
  constructor(managers) {
    this.managers = managers;
    this.toTable();
  }

  toTable() {
    const headersRow0 = [
      HEADERS.FIO,
      HEADERS.COMPANY,
      HEADERS.EMAIL,
      HEADERS.PHONE,
      HEADERS.TAXPAYER_ID_NUMBER,
    ];

    const rows = this.managers.map((m) => {
      const manager = new ManagerTableModel(m);

      return [
        manager.fullName || NOT_DATA_TEXT,
        manager.company || NOT_DATA_TEXT,
        manager.email || NOT_DATA_TEXT,
        manager.phone || NOT_DATA_TEXT,
        manager.taxpayer_id_number || NOT_DATA_TEXT,
      ];
    });

    const data = [
      headersRow0,
      ...rows,
    ];

    const ws = XLSX.utils.aoa_to_sheet(data);
    // const merge = [
    //   { s: { r: 0, c: 9 }, e: { r: 0, c: 21 } },
    //   { s: { r: 0, c: 23 }, e: { r: 0, c: 25 } },
    //   { s: { r: 0, c: 26 }, e: { r: 0, c: 37 } },
    // ];
    // ws['!merges'] = merge;

    // ws['!cols'] = [
    //   { wch: Math.max(HEADERS.FIO.length, 30) },
    //   { wch: Math.max(HEADERS.GENDER.length, 20) },
    //   { wch: Math.max(HEADERS.BIRTHDATE.length, 20) },
    // ];

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Менеджеры');

    XLSX.writeFile(wb, 'Менеджеры.xlsx');

    return this.managers;
  }
}
