<template>
  <v-data-table :headers="headers" :items="managers"
                class="px-5"
                :loading="loading.load"
                loading-text="Загрузка... Пожалуйста подождите"
                :mobile-breakpoint="300"
                :height="tableHeight"
                fixed-header
                hide-default-footer
                :custom-sort="customSort"
                :items-per-page="-1"
    >
    <template v-slot:header.stock_balance="{ }">
      Баланс<br>тестов:<br>
    </template>
    <template v-slot:header.candidates_count="{ }">
      Кол-во<br>кандидатов:<br>
    </template>

    <template v-slot:header.col_tests="{ }">
      Потенциальная потребность:<br>
      <v-select
          class="managers-tests-header-select"
          :items="allowedTests"
          _outlined dense
          v-model="selectedTestInd"
      />
    </template>

    <template v-slot:top>
      <v-toolbar flat>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialogDelete" max-width="600px">
          <v-card class="pa-8 pa-sm-16">
            <h2>Удалить?</h2>
            <div class="mt-6">
              Вы уверены, что хотите удалить менеджера?<br>Действие не может быть отменено.
            </div>
            <div class="mt-12 d-flex justify-center">
              <v-btn @click="closeDelete" class="px-10" large outlined text>Отмена</v-btn>
              <v-btn @click="deleteItemConfirm" class="ml-4 px-10" color="error" large>Удалить</v-btn>
            </div>
          </v-card>
        </v-dialog>

        <ManagersTableActions v-if="selected.length" class="d-none d-sm-block" />
<!--        <ManagerCreateModalDeleteModal />-->
      </v-toolbar>
    </template>

    <template v-slot:item.check="{ item }">
      <div>
        <v-checkbox :input-value="selected.some((id) => id === item.id)"
                    @change="setSelected(item.id)" hide-details class="mt-auto"/>
      </div>
    </template>

    <template v-slot:item.fullname="{ item }">
      <div class="d-flex justify-space-between align-center">
        <router-link v-if="$store.getters['auth/isAllowed']('view_manager')"
           class="text-primary mr-2" :class="{'grey--text lighten-2': !item.full_name && !item.first_name}"
          :to="{ name: 'managers-id', params: { id: item.id } }"
        >
          {{ getFullName(item) }}
        </router-link>
        <span v-else >
          {{ getFullName(item) }}
        </span>
      </div>
    </template>

    <template v-slot:item.company="{ item }">
      <div class="d-flex justify-space-between align-center">
        {{item.customer ? item.customer.company : ''}}
      </div>
    </template>

    <template v-slot:item.col_tests="{ item }">
        {{ getTestsCount(item) }}
    </template>

    <template v-slot:no-data>
      Менеджеров не найдено.
    </template>

    <template v-slot:footer="{ props }">
        <v-data-footer
          :pagination="props.pagination"
          :options="props.options"
          items-per-page-all-text="Все"
          items-per-page-text="Менеджеров на странице:"
          :items-per-page-options="[-1]"
          :page-text="`Всего менеджеров: ${props.pagination.itemsLength}. Страница ${props.options.page} из ${props.pagination.pageCount}`"
          show-current-page
          show-first-last-page
        />
    </template>
  </v-data-table>
</template>

<script>
/*eslint-disable*/
import { mapActions, mapGetters } from 'vuex';
import ManagersTableActions from '@/components/managers/table/ManagersTableActions';

export default {
  name: 'ManagersTable',
  components: {
    ManagersTableActions,
  },
  data: () => ({
    tableHeight: 400,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Id', value: 'id', width: '60px',
      },
      {
        text: 'Фамилия и имя', align: 'start', value: 'fullname', width: '280px', sortable: true,
      },
      {
        text: 'Название компании', align: 'start', value: 'company', width: '250px',
      },
      {
        text: 'Электронная почта', value: 'email', width: '250px',
      },
      {
        text: 'Баланс тестов', value: 'stock_balance', width: '100px',
      },
      {
        text: 'Кол-во кандидатов', value: 'candidates_count', width: '100px',
      },
      {
        text: 'Потенциальная потребность', value: 'col_tests', width: '190px',
      },
    ],
    paginationData: null,
    selectedTestInd: 0,
  }),
  computed: {
    ...mapGetters({
      loading: 'users/managers/loading',
      managers: 'users/managers/managers',
      errors: 'users/managers/errors',
      selected: 'users/managers/selected',
      count: 'users/managers/count',
      filter: 'users/managers/filter',
      allowedStdTests: 'tests/allowedStdTests',
    }),
    allowedTests() {
      return [
        { value: '', text: 'Все' },
        ...this.allowedStdTests
          .map(el => ({ value: el.id, text: el.title }))
      ];
    },

  },
  methods: {
    ...mapActions({
      loadManagers: 'users/managers/loadManagers',
      createManager: 'users/managers/createManager',
      clearError: 'users/managers/clearError',
      setSelected: 'users/managers/setSelected',
      allSelected: 'users/managers/allSelected',
      clearSelected: 'users/managers/clearSelected',
      setFilterPage: 'users/managers/setFilterPage',
    }),
    deleteItem(item) {
      this.editedIndex = this.managers.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.managers.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    pagination(data) {
      this.paginationData = data;
    },
    onChangeAllCheck(bool) {
      if (bool) {
        this.allSelected();
      } else {
        this.clearSelected();
      }
    },
    getFullName(item) {
      return item.last_name || item.first_name ? `${item.last_name} ${item.first_name}` : 'Имя не задано';
    },
    getTestsCount(item) {
      if (this.selectedTestInd) {
        const test = item.tests.find(el => el.name === this.selectedTestInd);
        return test ? test.number_of_not_endowed_candidates : '-';
      }
      return item.tests.reduce((acc, cur) => acc + cur.number_of_not_endowed_candidates, 0);
    },
    customSort(items, [index], [isDesc]) {
      items.sort((a, b) => {
        if (index === 'fullname') {
          const aname = a.last_name || a.first_name ? `${a.last_name} ${a.first_name}` : 'Я';
          const bname = b.last_name || b.first_name ? `${b.last_name} ${b.first_name}` : 'Я';
          return !isDesc ? aname.localeCompare(bname) : bname.localeCompare(aname);
        }
        if (index === 'col_tests') {
          const va = this.getTestsCount(a);
          const vb = this.getTestsCount(b);
          return isDesc ? (vb < va ? -1 : 1) : (va < vb ? -1 : 1);
        }
        if (index === 'company') {
          const aname = a.customer && a.customer.company ? a.customer.company : 'Z';
          const bname = b.customer && b.customer.company ? b.customer.company : 'Z';
          return !isDesc ? aname.localeCompare(bname) : bname.localeCompare(aname);
        }


        if (!isDesc) {
          return a[index] < b[index] ? -1 : 1;
        }
        return b[index] < a[index] ? -1 : 1;
      });
      return items;
    },
  },
  created() {
    this.loadManagers();
  },
  mounted() {
    const height = document.documentElement.clientHeight - 270;
    this.tableHeight = Math.max(height, this.tableHeight);
    // console.log(this.allowedTests);
    // eslint-disable-next-line
    this.selectedTestInd = this.allowedTests?.length ? this.allowedTests[0].value : '';
  },
};
</script>

<style>
.managers-tests-header-select {
  font-size: 11px !important;
}

.v-application--is-ltr .managers-tests-header-select .v-input__append-inner {
  padding-left: 0px !important;
}

.managers-tests-header-select.v-select.v-input--dense .v-select__selection--comma {
  margin: 5px 0 3px 0 !important;
}

</style>
