<template>
  <div class="page">

    <div class="row">
      <div class="col-10 d-flex align-center">
        <h1 class="font-lg">Менеджеры</h1>
      </div>
      <div class="col-2 d-flex align-center" v-if="$store.getters['auth/isAllowed']('add_manager')">
        <v-btn class="ml-auto d-sm-none" color="primary" small fab @click="setDialogCreateShow(true)">
          <v-icon small>mdi-account-plus</v-icon>
        </v-btn>
        <v-btn class="ml-auto d-none d-sm-block" color="primary" fab @click="setDialogCreateShow(true)">
          <v-icon small>mdi-account-plus</v-icon>
        </v-btn>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <ManagersTable />
      </div>
    </div>

    <ManagerCreateModal />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { COLORS } from '@/common/constants/colors';
import ManagersTable from '@/components/managers/table/ManagersTable';
import ManagerCreateModal from '@/components/managers/ManagerCreateModal';

export default {
  name: 'Managers',
  metaInfo: {
    title: 'Менеджеры',
  },
  components: {
    ManagerCreateModal,
    ManagersTable,
  },
  data: () => ({
    colors: COLORS,
    sheet: false,
    remainsOfTests: false,
    usedTotal: true,
  }),
  computed: {
    ...mapGetters({
      selected: 'users/managers/selected',
    }),
  },
  methods: {
    ...mapActions({
      setSelected: 'users/managers/setSelected',
      setDialogCreateShow: 'users/managers/setDialogCreateShow',
    }),
  },
  beforeDestroy() {
    this.setSelected(null);
  },
};
</script>
