<template>
  <v-dialog v-model="dialogCreateShow" max-width="700px">
    <v-card class="pa-8 pa-sm-16">
      <v-btn class="button-close ma-4" icon @click="dialogCreateShow = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <h2 class="text-center">Данные менеджера</h2>
      <form @submit.prevent="createManagerConfirm" class="mt-6">
        <div class="container">
          <div class="row">
            <div class="col-md-6 pb-0">
              <v-text-field label="Фамилия"
                            :value="editedItem.last_name"
                            @input="$v.editedItem.last_name.$model = upperFirst($event), onInput('last_name')"
                            :error-messages="lastnameErrors"
                            outlined />
            </div>
            <div class="col-md-6 pb-0">
              <v-text-field label="Имя"
                            :value="editedItem.first_name"
                            @input="$v.editedItem.first_name.$model = upperFirst($event), onInput('first_name')"
                            :error-messages="firstnameErrors"
                            outlined />
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 pb-0">
              <v-text-field label="Компания *"
                            v-model.trim="editedItem.company"
                            @input="onInput('company')"
                            @blur="$v.editedItem.company.$touch()"
                            :error-messages="companyErrors"
                            outlined />
            </div>
            <div class="col-md-6 pb-0">
              <v-text-field label="Адрес эл. почты *"
                            v-model.trim="editedItem.email"
                            @input="clearError"
                            @blur="$v.editedItem.email.$touch()"
                            :error-messages="emailErrors"
                            outlined />
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 pb-0">
              <v-text-field label="ИНН *"
                            v-model.trim="editedItem.taxpayer_id_number"
                            @input="clearError"
                            @blur="$v.editedItem.taxpayer_id_number.$touch()"
                            :error-messages="taxpayerIdNumberErrors"
                            outlined />
            </div>
          </div>

          <div v-for="(err, ind) in errors.create" :key="ind" class="error--text">
            <template v-if="!editedItem.hasOwnProperty(err[0])">
              {{err}}
              <!-- <div v-for="(e, i) in err[1]" :key="i">{{e}}</div> -->
            </template>
          </div>

          <div class="row mt-3">
            <div class="col-md-6 mx-auto">
              <v-btn type="submit" class="w-100" x-large color="primary"
                     :loading="loading.create"
                     :disabled="loading.create">
                Сохранить
              </v-btn>
            </div>
          </div>
        </div>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import managerEditItemMixin from '@/components/managers/managerEditItemMixin';
import { upperFirst } from 'lodash';

export default {
  name: 'ManagerCreateModal',
  mixins: [managerEditItemMixin],
  methods: {
    upperFirst,
  },
};
</script>

<style scoped>
.error--text {
  display: flex;
}
</style>
